import React from "react"
// import { Link, graphql } from 'gatsby'
import Layout from "../components/Layout"
// import styles from '../styles/home.module.css'
// import Img from "gatsby-image"

export default function Home() {
  // { data }
  // console.log(data)
  
  return (
    <Layout>
      {/* <section className={styles.header}> */}
        <div>
          <h2>About me</h2>
          <p>I am some one with some info</p>
          <p>I am some one with some info</p>
          <p>I am some one with some info</p>
          <p>I am some one with some info</p>
          {/* <Link className={styles.btn} to="/projects">My Portfolio Projects</Link> */}
        </div>
        {/* <Img fluid={data.file.childImageSharp.fluid} /> */}

      {/* </section> */}
    </Layout>
  )
}

// export const query = graphql`
//   query Banner {
//     file(relativePath: {eq: "banner.png"}) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `